import './App.css';
import Creators from './Components/Creators/Creators';
import Home from './Components/Home/Home';
import Showcase from './Components/Showcase/Showcase';
import Traits from './Components/Trails/Traits';

function App() {
    return (
        <div className="body">
            <Home />
            <Showcase />
            <Traits />
            <Creators />
        </div>
    );
}

export default App;

// import React from 'react';
// import Navbar from './Navbar';
// import './App.css';

// function App() {
//     return (
//         <div className="App">
//             <Navbar />
//             <p>
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero amet nam expedita laboriosam quos odio doloremque, dolor accusantium eaque, suscipit natus
//                 culpa commodi alias deserunt quod, distinctio deleniti nulla delectus officia velit dolore ea voluptas aperiam! Consequuntur, dolores eveniet rerum perferendis nemo
//                 ipsum quod deserunt voluptas. Numquam quaerat libero quos magnam repellat aperiam sit blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
//                 accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium, dolor recusandae
//                 maiores quae necessitatibus, pariatur veniam. Harum doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat error vel eos laborum facere, quasi
//                 iste esse hic suscipit quidem voluptates temporibus. In quos veritatis consequatur, fugiat dolorum velit nam corporis accusantium sunt assumenda magni quo
//                 cupiditate aut aperiam placeat distinctio! Voluptates earum porro quidem accusamus veritatis dignissimos iusto fugit in ad minima atque temporibus officia, eligendi
//                 expedita, pariatur laborum maiores quisquam! Voluptatum, quia nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga vero quam voluptas totam
//                 inventore aliquam! Eaque necessitatibus tempora corrupti distinctio voluptas illo quas eveniet tempore, explicabo ratione cum! Illum qui facilis provident autem
//                 ipsum, assumenda sint exercitationem dicta delectus suscipit.
//             </p>
//             <p>
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero amet nam expedita laboriosam quos odio doloremque, dolor accusantium eaque, suscipit natus
//                 culpa commodi alias deserunt quod, distinctio deleniti nulla delectus officia velit dolore ea voluptas aperiam! Consequuntur, dolores eveniet rerum perferendis nemo
//                 ipsum quod deserunt voluptas. Numquam quaerat libero quos magnam repellat aperiam sit blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
//                 accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium, dolor recusandae
//                 maiores quae necessitatibus, pariatur veniam. Harum doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat error vel eos laborum facere, quasi
//                 iste esse hic suscipit quidem voluptates temporibus. In quos veritatis consequatur, fugiat dolorum velit nam corporis accusantium sunt assumenda magni quo
//                 cupiditate aut aperiam placeat distinctio! Voluptates earum porro quidem accusamus veritatis dignissimos iusto fugit in ad minima atque temporibus officia, eligendi
//                 expedita, pariatur laborum maiores quisquam! Voluptatum, quia nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga vero quam voluptas totam
//                 inventore aliquam! Eaque necessitatibus tempora corrupti distinctio voluptas illo quas eveniet tempore, explicabo ratione cum! Illum qui facilis provident autem
//                 ipsum, assumenda sint exercitationem dicta delectus suscipit.
//             </p>
//             <p>
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero amet nam expedita laboriosam quos odio doloremque, dolor accusantium eaque, suscipit natus
//                 culpa commodi alias deserunt quod, distinctio deleniti nulla delectus officia velit dolore ea voluptas aperiam! Consequuntur, dolores eveniet rerum perferendis nemo
//                 ipsum quod deserunt voluptas. Numquam quaerat libero quos magnam repellat aperiam sit blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
//                 accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium, dolor recusandae
//                 maiores quae necessitatibus, pariatur veniam. Harum doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat error vel eos laborum facere, quasi
//                 iste esse hic suscipit quidem voluptates temporibus. In quos veritatis consequatur, fugiat dolorum velit nam corporis accusantium sunt assumenda magni quo
//                 cupiditate aut aperiam placeat distinctio! Voluptates earum porro quidem accusamus veritatis dignissimos iusto fugit in ad minima atque temporibus officia, eligendi
//                 expedita, pariatur laborum maiores quisquam! Voluptatum, quia nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga vero quam voluptas totam
//                 inventore aliquam! Eaque necessitatibus tempora corrupti distinctio voluptas illo quas eveniet tempore, explicabo ratione cum! Illum qui facilis provident autem
//                 ipsum, assumenda sint exercitationem dicta delectus suscipit.
//             </p>
//             <p>
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero amet nam expedita laboriosam quos odio doloremque, dolor accusantium eaque, suscipit natus
//                 culpa commodi alias deserunt quod, distinctio deleniti nulla delectus officia velit dolore ea voluptas aperiam! Consequuntur, dolores eveniet rerum perferendis nemo
//                 ipsum quod deserunt voluptas. Numquam quaerat libero quos magnam repellat aperiam sit blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
//                 accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium, dolor recusandae
//                 maiores quae necessitatibus, pariatur veniam. Harum doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat error vel eos laborum facere, quasi
//                 iste esse hic suscipit quidem voluptates temporibus. In quos veritatis consequatur, fugiat dolorum velit nam corporis accusantium sunt assumenda magni quo
//                 cupiditate aut aperiam placeat distinctio! Voluptates earum porro quidem accusamus veritatis dignissimos iusto fugit in ad minima atque temporibus officia, eligendi
//                 expedita, pariatur laborum maiores quisquam! Voluptatum, quia nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga vero quam voluptas totam
//                 inventore aliquam! Eaque necessitatibus tempora corrupti distinctio voluptas illo quas eveniet tempore, explicabo ratione cum! Illum qui facilis provident autem
//                 ipsum, assumenda sint exercitationem dicta delectus suscipit.
//             </p>
//             <p>
//                 Lorem ipsum, dolor sit amet consectetur adipisicing elit. Nisi libero amet nam expedita laboriosam quos odio doloremque, dolor accusantium eaque, suscipit natus
//                 culpa commodi alias deserunt quod, distinctio deleniti nulla delectus officia velit dolore ea voluptas aperiam! Consequuntur, dolores eveniet rerum perferendis nemo
//                 ipsum quod deserunt voluptas. Numquam quaerat libero quos magnam repellat aperiam sit blanditiis fugiat? Dolor quasi nostrum illum ipsa blanditiis non ullam,
//                 accusamus fugiat ut unde! Quidem ipsa quos enim quia vel ab dolore autem ut veritatis corrupti distinctio dicta iste ad consequatur, accusantium, dolor recusandae
//                 maiores quae necessitatibus, pariatur veniam. Harum doloribus ipsam natus, necessitatibus dignissimos quos quo quae, placeat error vel eos laborum facere, quasi
//                 iste esse hic suscipit quidem voluptates temporibus. In quos veritatis consequatur, fugiat dolorum velit nam corporis accusantium sunt assumenda magni quo
//                 cupiditate aut aperiam placeat distinctio! Voluptates earum porro quidem accusamus veritatis dignissimos iusto fugit in ad minima atque temporibus officia, eligendi
//                 expedita, pariatur laborum maiores quisquam! Voluptatum, quia nulla ipsum dolor corrupti reiciendis natus quasi deleniti eius fuga vero quam voluptas totam
//                 inventore aliquam! Eaque necessitatibus tempora corrupti distinctio voluptas illo quas eveniet tempore, explicabo ratione cum! Illum qui facilis provident autem
//                 ipsum, assumenda sint exercitationem dicta delectus suscipit.
//             </p>
//         </div>
//     );
// }

// export default App;
